.grecaptcha-badge {
    visibility: hidden;
}

body.recaptcha {
    .grecaptcha-badge {
        visibility: visible;
        margin-bottom: 50px;
    }
}

.add-button-container {
    margin-bottom: 8px;
}
